import React from "react"
import { Link, StandardButton } from "@life-without-barriers/react-components"

const CalloutPackagesDifference = ({ className }: { className?: string }) => (
  <div className={`br2 lwb-shadow bg-white overflow-hidden ${className ?? ""}`}>
    <div className="bg-lwb-theme-medium pt2 pb1" />
    <div className="pa3 pa4-ns copy tc">
      <h3 className="ts-display-4 fw7 color-lwb-theme-medium">
        What is the difference between CHSP and Home Care Packages?
      </h3>
      <p className="mt4">
        Both give you support to stay at home, but there are differences between
        the programs.
      </p>
      <div className="mt4">
        <Link
          className="no-underline"
          to="/services/aged-care/commonwealth-home-support-programme-and-home-care-packages"
        >
          <StandardButton shade="xdark" className="w-100 center">
            Learn More
          </StandardButton>
        </Link>
      </div>
    </div>
  </div>
)

export default CalloutPackagesDifference
