import React from "react"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { Site } from "@life-without-barriers/gatsby-common"
import styled, { withTheme, ThemeProps } from "styled-components"
import {
  Helmet,
  Banner,
  Link,
  media,
  TickListItem,
  Layout,
  Theme,
  Interfaces,
  ReadMoreLink,
  Section,
  Container,
  Row,
  Box,
  Heading,
  routesObject
} from "@life-without-barriers/react-components"
import { contentfulHelpers as Contentful } from "@life-without-barriers/utilities"

import ContactUsSection from "../../../components/services/aged-care/ContactUsSection"
import Callout from "../../../components/services/aged-care/CalloutPackagesDifference"
import { AgedCareForm } from "@life-without-barriers/shared-contact-form"
const { agedCareThemeFull } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
    pageResources: {
      edges: [
        {
          node: {
            pageDocuments: Contentful.RemoteFileAsset[]
          }
        }
      ]
    }
  }
}

const PackageItem = ({
  className,
  text
}: {
  className?: string
  text: string
}) => (
  <li className={`w-50-ns ${className ?? ""}`}>
    <span className="db br2 pv3 ph2 ph3-ns tc fw8 mr3-ns color-lwb-theme-medium">
      {text}
    </span>
  </li>
)

const PageForm = (
  <AgedCareForm
    formId="desktop-home-care-packages"
    enquiryType="Home Care Packages"
  />
)

export default withTheme(
  ({
    location,
    data: {
      site: { siteMetadata },
      bannerImage,
      pageResources
    }
  }: Props & ThemeProps<Interfaces.Theme>) => {
    const currentFeeSchedule = pageResources.edges[0].node.pageDocuments[0]
    return (
      <Layout
        theme={agedCareThemeFull}
        fixedCTAPhoneNumber="1800792359"
        fixedCTAContact={PageForm}
      >
        <div>
          <Helmet
            title={`Home Care Packages | ${siteMetadata.title}`}
            description="For people needing ongoing support or multiple supports from a broad range of services, My Aged Care may assess you as eligible for the Home Care Package program."
            image={bannerImage}
            siteUrl={siteMetadata.siteUrl}
            path={location.pathname}
            themeColor={agedCareThemeFull.medium}
          />
          <Banner
            title="Home Care Packages"
            image={bannerImage}
            breadcrumbs={[
              routesObject.home,
              routesObject.service,
              {
                to: "/services/aged-care/",
                text: "Aged care services",
                title: "Aged care services"
              }
            ]}
          />
          <Container>
            <Row className="flex-wrap">
              <Box className="layout-readable">
                <Heading size={2}>What is a Home Care Package?</Heading>
                <p>
                  For people needing ongoing support or multiple supports from a
                  broad range of services, My Aged Care may assess you as
                  eligible for the Home Care Package program.
                </p>
                <p>
                  The Home Care Package program is a coordinated package of
                  supports tailored to you, enabling you to stay at home longer
                  with choice and control over the support you receive to do so.
                </p>
                <p>
                  There are four levels of home care packages to help meet the
                  different levels of care needs. There is government subsidy
                  depending on your package/needs.
                </p>
              </Box>
              <Box className="w-100">
                <StyledPackageItems className="flex-ns flex-wrap-ns list pa0 ma0 mt4">
                  <PackageItem text="Level 1 - Basic Care Needs" />
                  <PackageItem
                    text="Level 2 - Low Level Care Needs"
                    className="mt3 mt0-ns"
                  />
                  <PackageItem
                    text="Level 3 - Intermediate Care Needs"
                    className="mt3"
                  />
                  <PackageItem
                    text="Level 4 - High Level Care Needs"
                    className="mt3"
                  />
                </StyledPackageItems>
              </Box>
            </Row>
          </Container>
          <Section background="bg-lwb-theme-xxx-light">
            <Container>
              <Row>
                <Box>
                  <Heading size={2}>
                    Home Care Packages can provide a wide range of supports to
                    you, including:
                  </Heading>
                  <StyledTickListItems className="mt4 pl1 mb0">
                    {[
                      "Personal care supports including showering, grooming and medication support",
                      "Support with nutrition, meal preparation and diet",
                      "Continence Management including assessment and products",
                      "Mobility and adaptive equipment",
                      "Nursing care and assessment",
                      "Allied health therapy and assessment, including occupational therapy, physiotherapy and podiatry",
                      "Transport, either supported or through vouchers",
                      "Help around the home including cleaning and garden maintenance",
                      "Home maintenance and modifications",
                      "Assistive technology and telehealth",
                      "Community Access and Social Support including shopping and support to appointments",
                      "Social Groups in one of our centres or out and about in the community"
                    ].map((item, i) => (
                      <TickListItem
                        text={item}
                        key={i}
                        className={i === 0 ? "w-90-ns" : "mt4 w-90-ns"}
                      />
                    ))}
                  </StyledTickListItems>
                </Box>
              </Row>
            </Container>
          </Section>
          <Container>
            <Row className="flex-wrap relative">
              <Box className="w-100 w-seven-twelfths-l layout-gap">
                <Callout className="dn-l mb4 mb0-l" />
                <Heading size={2}>Am I eligible?</Heading>
                <p>
                  Home care packages are intended for people who are over 65
                  years of age, or 50 years of age for Aboriginal and Torres
                  Strait Islanders. You may be eligible for a home care package
                  if you are:
                </p>
                <ul className="ma0 mt3 pl4">
                  <li className="mb2">
                    an older person who needs coordinated services to help you
                    to stay in your home
                  </li>
                  <li>
                    a younger person with a disability, dementia or other
                    special care needs that are not met through other specialist
                    services (e.g. NDIS)
                  </li>
                </ul>
                <Heading size={2} topMargin="standard">
                  How much does it cost?
                </Heading>
                <ReadMoreLink
                  text="View our current fees and charges"
                  href={currentFeeSchedule.file.url}
                  className="mt4"
                />
                <p>
                  Our fees and charges outline some of the common services that
                  you may want to access using your Home Care Package. If you
                  require another service or equipment not listed in the
                  attached, our friendly team will work with you to find the
                  best possible options. This can include Social Support, Allied
                  Health, Home Maintenance, Assistive Technology and other
                  services to suit your needs.
                </p>
                <Heading size={3}>Additional Contributions</Heading>
                <p>
                  While Home Care Packages are subsidised through the
                  government, you may be asked to pay a contribution towards
                  your package.
                </p>
                <Heading size={3}>Income Tested Care Fee</Heading>
                <p>
                  When you enter into a Home Care Package, you will need to
                  complete a formal income assessment through the Department of
                  Human Services or Department of Veteran’s Affairs. This income
                  assessment is used by the government to determine whether you
                  need to pay a contribution towards your Home Care Package to
                  the your service provider. This fee is payable for each day
                  that you are in a Home Care Package agreement.
                </p>
                <p>
                  For more information about Income Tested Care Fees, you can
                  access the{" "}
                  <Link
                    className="normal black underline"
                    to="https://www.myagedcare.gov.au/costs/help-home-costs/income-assessment"
                    title="Access My Aged Care website"
                  >
                    My Aged Care website
                  </Link>{" "}
                  or contact one of our friendly staff to discuss the process.
                </p>
                <Heading size={3}>Basic Daily Care Fee</Heading>
                <p>
                  The Basic Daily Care Fee is a daily fee that you can pay
                  towards the cost of your care and supports under a Home Care
                  Package. The maximum fee that can be charged is 17.5% of the
                  single person aged pension payment.
                </p>
                <p>
                  Here at Life Without Barriers, we do not charge the Basic
                  Daily Care Fee as a general rule. If you need more supports
                  through your Home Care Package than the subsidy can provide,
                  the Basic Daily Care may apply to top up your package funds.
                </p>
              </Box>
              <Box className="dn db-l w-34-l absolute-l top-0-l right-0-l z-5 mt4 mt0-l">
                <Callout className="mb4-l" />
                {PageForm}
              </Box>
            </Row>
          </Container>
          <ContactUsSection
            title="Getting Started"
            formId="mobile-home-care-packages"
            formTitle="Talk to a specialist"
            contactMethod="phone"
            enquiryType="Home Care Packages"
            hideMyAgedCareField={false}
          />
        </div>
      </Layout>
    )
  }
)

export const query = graphql`
  {
    bannerImage: file(
      relativePath: { regex: "/img-home-care-packages2.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    pageResources: allContentfulPageResources(
      filter: {
        title: { eq: "home-care-packages" }
        pageDocuments: {
          elemMatch: {
            title: { eq: "Home-Care-Packages-Current-Fee-Schedule" }
          }
        }
      }
    ) {
      edges {
        node {
          pageDocuments {
            file {
              url
            }
            title
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

const StyledPackageItems = styled.ul`
  li:nth-child(1) span {
    background-color: #eff8f9;
  }

  li:nth-child(2) span {
    background-color: #ecf7f8;
  }

  li:nth-child(3) span {
    background-color: #e6f5f5;
  }

  li:nth-child(4) span {
    background-color: #e0f2f3;
  }
`

const StyledTickListItems = styled.ul`
  ${media.notSmall`
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  `}
`
